
<template>
  <div>
    <CButton color="info" @click="goBack" class="mb-3">
      <i class="fas fa-arrow-left"></i>
      Atrás
    </CButton>
    <CRow>
      <CCol sm="4">
        <CCard>
          <CCardHeader> Perfil </CCardHeader>
          <CCardBody>
            <CForm>
              <CCol
                sm="12"
                class="text-center d-flex flex-column justify-content-center"
              >
                <img
                  src="https://i.ibb.co/0yVFDNw/perifl.png"
                  width="300px"
                  height="300px"
                  class="mx-auto img-fluid"
                  alt="imagen del usuario"
                />
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="8">
        <CCard>
          <CCardBody>
            <CTabs add-tab-classes="mt-1">
              <CTab active>
                <template slot="title">
                  <CIcon name="cil-calculator" /> {{ tabs[0] }}
                </template>

                <!-- DATOS DEL USUARIO -->

                <CForm>
                  <CInput
                    placeholder="Id..."
                    type="hidden"
                    autocomplete="id"
                    :value.sync="id"
                  >
                    <template #prepend-content></template>
                  </CInput>

                  <CInput
                    placeholder="Nombres..."
                    label="Nombres:"
                    type="text"
                    autocomplete="nombre"
                    horizontal
                    :value.sync="user"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <!--
                  <CInput
                    placeholder="Apellidos..."
                    label="Apellidos:"
                    type="text"
                    autocomplete="apellidos"
                    horizontal
                    :value.sync="last_name"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user-follow"
                    /></template>
                  </CInput>
                  -->
                  <CInput
                    placeholder="Email..."
                    label="Email:"
                    type="email"
                    autocomplete="email"
                    horizontal
                    :value.sync="email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-envelope-closed"
                    /></template>
                  </CInput>
                  <!--
                  <CInput
                    placeholder="Tipo Documento..."
                    label="Tipo Documento:"
                    type="text"
                    disabled
                    autocomplete="tipo"
                    horizontal
                    :value.sync="document_type"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-bookmark"
                    /></template>
                  </CInput>
                  -->


                  <CInput
                    placeholder="Dni..."
                    label="Dni:"
                    type="text"
                    maxlength="8"
                    :value.sync="dni"
                    horizontal
                    v-on:keypress="isNumber($event)"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-file"
                    /></template>
                  </CInput>

                  <CInput
                    placeholder="Tipo Documento..."
                    type="hidden"
                    autocomplete="tipo"
                    horizontal
                    :value.sync="document_type"
                  >
                    <!--<template #prepend-content><CIcon name="cil-bookmark"/></template>-->
                  </CInput>

                  <!--
                  <CSelect
                      placeholder="Elija un estado"
                      :value.sync="state"
                      :options="data_state"
                    >
                    <template #prepend-content><CIcon name="cil-fullscreen-exit"/></template>
                  </CSelect>
                  -->

                  <CInput
                    placeholder="Token"
                    type="hidden"
                    autocomplete="tipo"
                    :value.sync="token"
                  >
                  </CInput>
                  <!--
                  <div class="form-group form-actions">
                    <CButton
                      color="info"
                      type="button"
                      :disabled="button"
                      @click="mtd_action"
                    >
                      <i class="fas fa-save"></i>
                      Guardar
                    </CButton>
                  </div>
                  -->
                </CForm>
              </CTab>
                <!-- CONTRASEÑA -->
              <!--
              <CTab>
                <template slot="title">
                  <CIcon name="cil-basket" /> {{ tabs[1] }}
                </template>

                <CForm>
                  <CInput
                    placeholder="Id..."
                    type="hidden"
                    autocomplete="id"
                    v-model="pass.id"
                  >
                    <template #prepend-content></template>
                  </CInput>

                  <CInput
                    placeholder="Digite Contraseña..."
                    label="Contraseña:"
                    type="password"
                    autocomplete="contraseña"
                    horizontal
                    v-model="pass.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-shield-alt"
                    /></template>
                  </CInput>

                  <div class="form-group form-actions">
                    <CButton
                      color="warning"
                      type="button"
                      :disabled="button"
                      @click="mtd_action_pass"
                    >
                      <i class="fas fa-save"></i>
                      Cambiar
                    </CButton>
                  </div>
                </CForm>
              </CTab>
              -->
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
//import CModalForm from "../../components/shared/modals/cModalForm.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  props: {
    button: Boolean,
  },
  name: "Profile",
  data() {
    return {
      prefix: "users",
      data_state: [
        { value: 1, label: "Activo" },
        { value: 2, label: "Inactivo" },
      ],
      tabs: ["Datos Personales", "Cambiar Contraseña"],
      activeTab: 1,
      id: "",
      user: "",
      last_name: "",
      email: "",
      //password: "",
      document_type: "",
      dni: "",
      rol_id: "",
      campu_id: "",
      departure_id: "",
      state: "",
      token: "",
      modal: {
        action: "update",
      },
      pass: {
        id: "",
        password: "",
      },
      login: {
        login: "",
        password: "",
      },
      users: {
        id: "",
        campu_id: "",
        departure_id: "",
        name: "",
        last_name: "",
        dni: "",
        document_type: "",
        rol_id: "",
        email: "",
        status: "",
        state: "",
        token: "",
        //password: "",
      },
    };
  },
  created() {
    this.id = this.$store.getters.get__user_id;
    this.user = this.$store.getters.get__user_name;
    this.last_name = this.$store.getters.get_all_user.last_name;
    this.email = this.$store.getters.get_all_user.email;
    this.document_type = this.$store.getters.get_all_user.document_type;
    this.dni = this.$store.getters.get_all_user.dni;
    // this.rol_id = this.$store.getters.get__user_rol_id;
    // this.campu_id = this.$store.getters.get__user_campu_id;
    // this.departure_id = this.$store.getters.get__user_departure_id;
    this.state = this.$store.getters.get__user_state;

    //!Token
    this.token = this.$store.getters.get__token;
  },

  methods: {
    ...mapActions(["get", "post"]),

    //? Validación número
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    //TODO: Cambio a los datos del usuario
    mtd_action: function () {
      // url a dónde enviamos

      this.users.id = this.id;
      this.users.name = this.user;
      this.users.last_name = this.last_name;
      //this.users.password = 1234567;//this.password;

      this.users.email = this.email;
      this.users.document_type = this.document_type;
      this.users.dni = this.dni;

      this.users.rol_id = this.rol_id; //this.rol_id;
      this.users.campu_id = this.campu_id;
      this.users.departure_id = this.departure_id;
      this.users.status = this.state; //this.departure_id;
      this.users.token = this.token;

      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.users,
      })
        .then((response) => {
          let color = "success";
          let message = "";

          if (this.modal.action == "store") {
            //?Para agregar

            if (response.state == 0) {
              this.data.push(response.data[0]);
              message = "REGISTRADO CORRECTAMENTE";
            } else {
              color = "danger";
              message = "DNI, YA REGISTRADO";
            }
          } else {
            //? Para modificar

            message = "EDITADO CORRECTAMENTE";
          }
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });

      //? Refrescar datos

      this.post({
        url: this.$store.getters.get__url + "/user/refresh",
        params: this.users,
      })
        .then((response) => {
          if (response.state == 200) {
            this.$store.commit("mt_set_auth", response.user);

            //? Actualizamos datos en la vista
            //! Refresco datos en el store
            this.$store.commit("mt_set_refresh", response.user);
          } else {
            alert("error");
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },

    // TODO: Cambio de contraseña
    mtd_action_pass: function () {
      let color = "success";
      let message = "";

      this.pass.id = this.id;
      this.pass.password = this.pass.password; //this.password;

      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/new_password",
        token: this.$store.getters.get__token,
        params: this.pass,
      })
        .then((response) => {
          message = "EDITADO CORRECTAMENTE.";

          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    goBack: function(){
      this.$router.replace({path: '/home'});
    }
  },
};
</script>
